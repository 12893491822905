







import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv3/atoms/Title.vue'
import CorrectGuid from '@/components/modules/drills/atoms/CorrectGuide.vue'
import Correct from '@/components/modules/drillsv3/organisms/Correct.vue'
import { PAGE_TYPE } from '@/store/modules/DrillsV3'
import ErrorMessage from '@/components/atoms/v3/ErrorMessage.vue'
import DrillsConfig from '@/mixins/drillsv3/Config'
import DrillWebApi from '@/mixins/drillsv3/WebApi'

@Component({
  components: {
    Title,
    CorrectGuid,
    Correct,
    ErrorMessage,
  },
})
export default class DrillScoring extends Mixins(DrillWebApi) {
  // 読み込み完了フラグ
  private loadComplete = false

  private error = false

  private drillModules = DrillsConfig.drillModules

  private version = this.$route.params.type

  protected async mounted() {
    this.$store.commit('drillsV3/setPageType', PAGE_TYPE.SCORING_UNIT)

    // 正誤入力情報を取得
    const version = ['v2', 'v3'].includes(this.version) ? this.version : 'v3'

    await this.$store.dispatch('drillsV3/setProblemCorrects', [[]])

    // 表示用の情報を取得し、ストアに保持
    let sProblemIds = []
    try {
      const paramSearch = this.$route.query.sProblemIds
      //@ts-ignore
      sProblemIds = JSON.parse(paramSearch)
    } catch {
      sProblemIds = []
    }
    if (!sProblemIds?.length) {
      this.error = true
      return
    }
    const problems = await this.getProblems([sProblemIds], version)
    await this.$store.dispatch('drillsV3/setProblems', problems)

    // 読み込み完了フラグを設定
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }
}
